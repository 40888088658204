import React, { useState, useEffect, useRef, useCallback } from 'react';
import LoadingIndicator from './LoadingIndicator';

const ChatWindow = ({ messages, sendMessage, isLoading, stopStreaming, isOpen, loading }) => {
  const [input, setInput] = useState('');
  const [remainingMessages, setRemainingMessages] = useState(null);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(() => Math.floor(Math.random() * 5) + 1);
  const [transitionClass, setTransitionClass] = useState('fade-in');
  const [usedIndices, setUsedIndices] = useState([Math.floor(Math.random() * 5) + 1]);

  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  const preMadeMessages = [
    "Come posso essere contattato?",
    "Ho un agenzia immobilare, dammi idee su come usare l'AI",
    "Mi occupo di pubblicità ed e-commerce, posso usare l'AI?",
    "Lavoro ogni giorno con l'assistenza clienti, che soluzioni proponi?",
    "Devo catalogare molte fatture contabili, c'è un modo per farlo in automatico?",
    "Nell'azienda di commercio che ho ci sono molti prodotti, come facico a seguirli tutti?"
  ];

  const getUnusedIndices = useCallback(() => {
    const allIndices = Array.from({ length: preMadeMessages.length - 1 }, (_, i) => i + 1);
    return allIndices.filter(index => !usedIndices.includes(index));
  }, [usedIndices, preMadeMessages.length]);

  const updateMessageIndex = useCallback(() => {
    setTransitionClass('fade-out');
    setTimeout(() => {
      setCurrentMessageIndex(prevIndex => {
        let newIndex;
        const unusedIndices = getUnusedIndices();

        if (unusedIndices.length === 0) {
          setUsedIndices([0]);
          newIndex = Math.floor(Math.random() * (preMadeMessages.length - 1)) + 1;
        } else {
          newIndex = unusedIndices[Math.floor(Math.random() * unusedIndices.length)];
        }

        setUsedIndices(prevUsed => [...prevUsed, newIndex]);
        return newIndex;
      });
      setTransitionClass('fade-in');
    }, 500); // Match this with the CSS transition duration
  }, [getUnusedIndices, preMadeMessages.length]);

  useEffect(() => {
    const intervalId = setInterval(updateMessageIndex, 9000); // Display duration extended to 12 seconds
    return () => clearInterval(intervalId);
  }, [updateMessageIndex]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (input.trim() && !isLoading && !loading) {
      sendMessage(input);
      setInput('');
    }
  }, [input, isLoading, loading, sendMessage]);

  const handlePreMadeMessageClick = useCallback((message) => {
    sendMessage(message);
  }, [sendMessage]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  }, [handleSubmit]);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = Math.min(textareaRef.current.scrollHeight, 90) + 'px';
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (isOpen) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    }
  }, [isOpen]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, adjustTextareaHeight]);

  // Check for critical system messages
  const hasCriticalSystemMessage = messages.some(msg => msg.from === 'system' && (
    msg.text.includes('La verifica reCAPTCHA non è riuscita') ||
    msg.text.includes('Errore di connessione') ||
    msg.text.includes('Limite giornaliero di messaggi raggiunto') ||
    msg.text.includes('Impossibile caricare la cronologia') ||
    msg.text.includes('Impossibile inizializzare la chat')
  ));

  return (
    <div className="chat-window">
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.from}`}>
            <div className="text">
              <strong>{msg.from === 'user' ? 'Io: ' : msg.from === 'system' ? 'Sistema: ' : 'ValerIA: '}</strong>
              {Array.isArray(msg.text) ? (
                msg.text.map((part, i) => (
                    <React.Fragment key={i}>
                    {part}
                    </React.Fragment>
                ))
                ) : (
                msg.text
                )}
              {msg.isLoading && <LoadingIndicator />}
            </div>
          </div>
        ))}
        {!loading && !hasCriticalSystemMessage && !messages.some(msg => msg.from === 'user') && (
          <>
            <div className="pre-made-messages-title">Domande Frequenti:</div>
            <div className="pre-made-messages">
              <div className="pre-made-message static" onClick={() => handlePreMadeMessageClick(preMadeMessages[0])}>
                {preMadeMessages[0]}
              </div>
              <div className={`pre-made-message dynamic ${transitionClass}`} onClick={() => handlePreMadeMessageClick(preMadeMessages[currentMessageIndex])}>
                {preMadeMessages[currentMessageIndex]}
              </div>
            </div>
          </>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form className="input-area" onSubmit={handleSubmit}>
        {loading ? (
          <div className="loading-indicator">Verifica di sicurezza in corso... attendi pochi secondi</div>
        ) : (
          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Scrivi qui..."
            disabled={isLoading || loading}
            rows="1"
            style={{ resize: 'none' }}
          />
        )}
        {!loading && (
          <div className="send-button-wrapper">
            {isLoading ? (
              <button type="button" className="send-button stop-button" onClick={stopStreaming}>
                <span className="material-symbols-outlined">stop</span>
              </button>
            ) : (
              <button type="submit" className="send-button" disabled={loading}>
                <span className="material-symbols-outlined">arrow_circle_up</span>
              </button>
            )}
          </div>
        )}
      </form>
      {remainingMessages !== null && remainingMessages <= 15 && (
        <div className="char-counter">
          You have {remainingMessages} messages left for today.
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
