// src/App.js
import React from 'react';
import Chatbot from './Chatbot';
import './App.css';

function App() {
  return (
    <div className="App">
      
      <main>
        <Chatbot />
      </main>
    </div>
  );
}

export default App;
