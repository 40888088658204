import { useState, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';

const useFontLoader = (fontName) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const loadFont = async () => {
      try {
        const font = new FontFaceObserver(fontName);
        await font.load();
        setFontLoaded(true);
      } catch (error) {
        console.error('Error loading font:', error);
        setFontLoaded(false);
      }
    };

    loadFont();
  }, [fontName]);

  return fontLoaded;
};

export default useFontLoader;
