import React, { useState, useEffect } from 'react';
import ChatWindow from './ChatWindow';
import ChatbotFooter from './ChatbotFooter'; // Import the new footer component
import Cookies from 'js-cookie';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from './axiosInstance';
import LoadingIndicator from './LoadingIndicator'; // Import the loading indicator
import useFontLoader from './useFontLoader'; // Import the custom hook

const ChatBotBackEndURLDev = "http://localhost:5001";
const ChatBotBackEndURLProd = "https://chat.sgaia.it"; 
const ChatBotBackEndURL = ChatBotBackEndURLProd; // remember to change axiosinstance too

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState(null);
  const [minimized, setMinimized] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stopStreamingFlag, setStopStreamingFlag] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [token, setToken] = useState(null); // JWT Token
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [showBubble, setShowBubble] = useState(false); // State for speech bubble
  const [bubbleContent, setBubbleContent] = useState(<LoadingIndicator />); // Initial content with loading indicator
  const [hasShownBubble, setHasShownBubble] = useState(false); // Add this state

  const USER_MARKER = "[USER]";
  const BOT_MARKER = "[BOT]";
  const RECAPTCHA_SITE_KEY = '6LdmivwpAAAAACCEFG-VBR-ddBRIqU0vPbq1I-W4';

  //const fontLoaded = useFontLoader('Material Symbols Outlined', 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
  const fontLoaded = useFontLoader('Material Symbols Outlined');
  useEffect(() => {
    const initializeChat = async () => {
      try {
        if (!recaptchaToken) {
          await loadReCaptcha();
          await verifyRecaptcha();
        }
        await fetchUserId();
        setLoading(false); // Set loading to false once all initialization is complete
      } catch (error) {
        console.error('Error initializing chat:', error);
        addSystemMessage('Impossibile inizializzare la chat, aggiorna la pagina o riprova più tardi');
        setLoading(false); // Set loading to false on error
      }
    };

    initializeChat();
  }, []);

  const loadReCaptcha = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = () => {
        addSystemMessage('La verifica reCAPTCHA non è riuscita.  Aggiorna la pagina e riprova');
        reject(new Error('Failed to load the reCAPTCHA script'));
      };
      document.body.appendChild(script);
    });
  };

  const verifyRecaptcha = async () => {
    return new Promise((resolve, reject) => {
      const checkRecaptchaReady = setInterval(() => {
        if (window.grecaptcha && window.grecaptcha.execute) {
          clearInterval(checkRecaptchaReady);
          window.grecaptcha.ready(async () => {
            try {
              const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'LOGIN' });
              const response = await axiosInstance.post('/api/verify_recaptcha', { token });
              if (response.data.success) {
                setRecaptchaToken(token);
                resolve();
              } else {
                addSystemMessage('La verifica reCAPTCHA non è riuscita.  Aggiorna la pagina e riprova');
                reject(new Error('reCAPTCHA verification failed.'));
              }
            } catch (error) {
              reject(error);
            }
          });
        }
      }, 100); // Check every 100ms if grecaptcha is ready
    });
  };

  const fetchUserId = async () => {
    try {
      // Initialize FingerprintJS
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;

      let storedUserId = Cookies.get('userId');
      let storedToken = Cookies.get('token');

      if (!storedUserId || !storedToken || storedUserId !== fingerprint) {
        const response = await axiosInstance.post('/api/create_user', { fingerprint, userAgent: navigator.userAgent });
        storedUserId = response.data.userId;
        storedToken = response.data.token;

        Cookies.set('userId', storedUserId, { expires: 365, secure: true });
        Cookies.set('token', storedToken, { expires: 365, secure: true });

        setUserId(storedUserId);
        setToken(storedToken);
      } else {
        setUserId(storedUserId);
        setToken(storedToken);
      }

      await loadConversation(storedUserId, storedToken);
    } catch (error) {
      addSystemMessage('Impossibile inizializzare la chat, aggiorna la pagina o riprova più tardi');
      console.error('Error fetching user ID:', error);
      throw error;
    }
  };

  useEffect(() => {
    console.log("hasshown", hasShownBubble);
    if (!hasShownBubble) {
      const showBubbleTimer = setTimeout(() => {
        setHasShownBubble(true); // Set the state to true after the effect runs
        setShowBubble(true);
        let index = 0;
        const text = "Ciao, sono ValerIA";
        let currentText = "";
  
        const startTyping = () => {
          const typingTimer = setInterval(() => {
            if (index < text.length) {
              currentText += text[index];
              setBubbleContent(currentText);
              index++;
            } else {
              clearInterval(typingTimer);
              setTimeout(() => {
                setShowBubble(false);
              }, 3000); // Bubble disappears after 3 seconds
            }
          }, 100); // Typing speed
        };
  
        setTimeout(startTyping, 1000); // Wait 1 second before starting typing
      }, 2000); // Show bubble after 2 seconds
  
      return () => {
        clearTimeout(showBubbleTimer);
      };
    }
  }, [minimized, hasShownBubble]);

  const addSystemMessage = (text) => {
    const newMessage = { from: 'system', text };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const parseBoldText = (text) => {
    const parts = text.split(/(\*\*[^**]*\*\*)/g);
    return parts.map((part, index) =>
      part.startsWith('**') && part.endsWith('**') ? (
        <strong key={index}>{part.slice(2, -2)}</strong>
      ) : (
        part
      )
    );
  };

  const parseMessageText = (text) => {
    return text.split('\n').flatMap((line, index) => [
      ...parseBoldText(line),
      index < text.split('\n').length - 1 ? <br key={`br-${index}`} /> : null
    ]).filter(part => part !== null);
  };

  const loadConversation = async (userId, token) => {
    try {
      const response = await axiosInstance.get(`/api/conversation?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const conversation = response.data.conversation;

      const messages = [];
      let currentMessage = '';
      let currentFrom = '';

      conversation.split('\n').forEach((line) => {
        console.log(line);
        if (line === '[USER INTERRUPTED THIS MESSAGE GENERATION]') { //message interrupted by the user
          line = "L'utente ha interrotto la generazione del messaggio";
        }
        if (line.startsWith(BOT_MARKER)) {
          if (currentMessage) {
            messages.push({ from: currentFrom, text: parseMessageText(currentMessage.trim()) });
          }
          currentMessage = line.replace(`${BOT_MARKER} `, '');
          currentFrom = 'bot';
        } else if (line.startsWith(USER_MARKER)) {
          if (currentMessage) {
            messages.push({ from: currentFrom, text: parseMessageText(currentMessage.trim()) });
          }
          currentMessage = line.replace(`${USER_MARKER} `, '');
          currentFrom = 'user';
        } else {
          currentMessage += '\n' + line;
        }
      });
      if (currentMessage.trim()) {
        messages.push({ from: currentFrom, text: parseMessageText(currentMessage.trim()) });
      }

      setMessages(messages);
    } catch (error) {
      console.error('Error loading conversation:', error);
      addSystemMessage('Impossibile caricare la cronologia. Aggiorna la pagina o riprova più tardi.');
    }
  };

  const sendMessage = async (message) => {
    if (!recaptchaToken) {
      console.error('reCAPTCHA token is not available.');
      addSystemMessage('La verifica reCAPTCHA non è riuscita.  Aggiorna la pagina e riprova');
      return;
    }
    setHasSentMessage(true);
    const userMessage = { from: 'user', text: parseMessageText(message) };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    let botReply = { from: 'bot', text: [], isLoading: true };
    setMessages((prevMessages) => [...prevMessages, botReply]);
    setStopStreamingFlag(false);
    setIsLoading(true);

    try {
      const response = await fetch(`${ChatBotBackEndURL}/api/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ message, user_id: userId })
      });

      if (response.status === 429) {
        const errorData = await response.json();
        if (errorData.error === "Message limit exceeded.") {
          setMessages((prevMessages) => prevMessages.slice(0, -1)); // Remove the last message (the one just sent)
          addSystemMessage("Limite giornaliero di messaggi raggiunto.");
        } else {
          addSystemMessage(errorData.error);
        }
        setIsLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const remainingMessages = response.headers.get('X-Remaining-Messages');
      if (remainingMessages !== null && parseInt(remainingMessages) <= 5) {
        setMessages((prevMessages) => prevMessages.slice(0, -1)); // Remove the last message (the one just sent)
        addSystemMessage(`Hai ancora ${remainingMessages} messaggi rimanenti per oggi.`);
        setMessages((prevMessages) => [...prevMessages, botReply]);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let botResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        botResponse += decoder.decode(value, { stream: true });

        const parts = botResponse.split('\n\n');
        for (const part of parts) {
          if (part.startsWith('data: ')) {
            const data = part.substring(6);
            if (data === '[END]') {
              setIsLoading(false);
              botReply = { ...botReply, isLoading: false };
              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1] = botReply;
                return newMessages;
              });
              return;
            } else if (data === '[USER INTERRUPTED THIS MESSAGE GENERATION]') {
              const decodedText = "L'utente ha interrotto la generazione del messaggio";
              botReply = { ...botReply, text: [...botReply.text, ...parseMessageText(decodedText)], isLoading: false };
              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1] = botReply;
                return newMessages;
              });
            } else {
              const decodedText = data.replace(/<NEWLINE>/g, '\n');
              botReply = { ...botReply, text: [...botReply.text, ...parseMessageText(decodedText)], isLoading: true };
              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1] = botReply;
                return newMessages;
              });
            }
          }
        }
        botResponse = '';
      }
    } catch (error) {
      console.error('Connection error:', error);
      addSystemMessage('Errore di connessione. Aggiorna la pagina o riprova più tardi. ');
      setIsLoading(false);
    }
  };

  const addMessage = (from, text) => {
    const newMessage = { from, text };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const stopStreaming = async () => {
    setStopStreamingFlag(true);
    setIsLoading(false);

    try {
      await fetch(ChatBotBackEndURL+'/api/stop_stream', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error sending stop signal:', error);
    }
  };

  const toggleMinimize = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      if (fullScreen) {
        setFullScreen(false);
        setTimeout(() => {
          setMinimized(true);
          setIsAnimating(false);
        }, 300);
      } else {
        setMinimized(!minimized);
        setIsAnimating(false);
      }
    }
  };

  const toggleFullScreen = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      if (minimized) {
        setMinimized(false);
        setTimeout(() => {
          setFullScreen(true);
          setIsAnimating(false);
        }, 300);
      } else {
        setFullScreen(!fullScreen);
        setIsAnimating(false);
      }
    }
  };

  const exportChat = () => {
    const chatContent = messages.map(msg => `${msg.from === 'user' ? 'Io: ' : 'ValerIA: '}${msg.text}`).join('\n');
    const blob = new Blob([chatContent], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'valerIA chat.txt';
    link.click();
  };

  const flushConversation = async () => {
    if (window.confirm('Sei sicuro di voler eliminare questa chat?')) {
      try {
        await axiosInstance.post('/api/flush_conversation', { user_id: userId }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setMessages([]);
        await loadConversation(userId, token);
      } catch (error) {
        console.error('Error flushing conversation:', error);
        addSystemMessage('Failed to clear conversation. Please try again later.');
      }

    }
  };

  useEffect(() => {
    const sendExitBeacon = () => {
      if (hasSentMessage) {
        const url = ChatBotBackEndURL + '/api/exit';
        const data = JSON.stringify({ token: token });
        navigator.sendBeacon(url, data);
      }
    };
  
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        sendExitBeacon();
        setHasSentMessage(false);
      }
    };
  
    window.addEventListener('beforeunload', sendExitBeacon);
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      window.removeEventListener('beforeunload', sendExitBeacon);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hasSentMessage, token]);

//   if (!fontLoaded) {
//     return null; // Show nothing while loading
//   }

  return (
    <>
      {minimized ? (
        <div className="minimized-icon" onClick={() => setMinimized(false)}>
          {fontLoaded && (
            <>
                <span className="material-symbols-outlined">comment</span>
                {showBubble && (
                <div className="speech-bubble show">
                    {bubbleContent}
                </div>
                )}
            </>
            )}
        </div>
      ) : (
        <div className={`chatbot ${fullScreen ? 'fullscreen' : ''} ${minimized ? 'minimized' : ''}`}>
          <div className="chatbot-header">
            <button onClick={exportChat}>
              <span className="material-symbols-outlined">download</span>
            </button>
            <button onClick={flushConversation}>
              <span className="material-symbols-outlined">delete</span>
            </button>
            <button onClick={toggleFullScreen}>
              <span className="material-symbols-outlined">{fullScreen ? 'compress' : 'open_with'}</span>
            </button>
            <button onClick={toggleMinimize}>
              <span className="material-symbols-outlined">minimize</span>
            </button>
          </div>
          <ChatWindow messages={messages} sendMessage={sendMessage} isLoading={isLoading} loading={loading} stopStreaming={stopStreaming} isOpen={!minimized} />
          <ChatbotFooter /> {/* Add the new footer component here */}
        </div>
      )}
    </>
  );
};

export default Chatbot;
