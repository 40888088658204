import React from 'react';

const ChatbotFooter = () => {
  return (
    <div className="chatbot-footer">
      <a href="#">Termini</a>
      <span>•</span>
      <a href="https://www.google.com/recaptcha" target="_blank" rel="noopener noreferrer">reCAPTCHA</a>
      <span>•</span>
      <span>powered by SGAIA</span>
    </div>
  );
};

export default ChatbotFooter;